import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { useIntl, Link } from 'gatsby-plugin-react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import './all.sass'
import './social-media-buttons.css'
import '../styles/bubbleSections.scss'

function Footer() {
  const intl = useIntl()
  const { locale } = intl

  return (
    <StaticQuery
      query={graphql`
        query {
          pl: allWpPage(
            sort: { fields: id }
            filter: {
              language: { language: { eq: "pl" } }
              doStopki: { wyswietlajWStopce: { eq: true } }
            }
          ) {
            edges {
              node {
                id
                title
                slug
              }
            }
          }
          en: allWpPage(
            sort: { fields: id }
            filter: {
              language: { language: { eq: "en" } }
              doStopki: { wyswietlajWStopce: { eq: true } }
            }
          ) {
            edges {
              node {
                id
                title
                slug
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          <section className="section-bubble5">
            <div className="section">
              <div>
                <div
                  className="container"
                  id="footerContainer"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                    }}
                    className="spacer"
                  >
                    {(locale == 'pl' ? data.pl : data.en).edges.map(edge => (
                      <Link
                        className="has-text-primary rainbow-link title-link is-size-5 m-2"
                        to={'/' + edge.node.slug}
                        key={edge.node.slug}
                      >
                        {edge.node.title}
                      </Link>
                    ))}
                  </div>
                  <div>
                    <a
                      href="https://www.instagram.com/kslemieszteam/"
                      className="icon-button twitter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                      <span></span>
                    </a>
                    <a
                      href="https://www.facebook.com/Lemiesz-Team-2136654143253901/?modal=admin_todo_tour"
                      className="icon-button facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                      <span></span>
                    </a>
                  </div>
                </div>
                <p style={{ fontSize: '1em' }}>
                  Designed by{' '}
                  <a
                    className="rainbow-link has-text-primary"
                    href="https://www.linkedin.com/in/%C5%82ukasz-micha%C5%82-barto%C5%9B-30b340110/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Łukasz Bartoś
                  </a>
                </p>
                <p className="is-hidden">
                <a
                    href="https://icons8.com/ouch/illustration/rush-13"
                    target="_blank"
                    rel="nofollow"
                  >
                    Icons8
                  </a>
                </p>
              </div>
            </div>
          </section>
        </div>
      )}
    />
  )
}

export default Footer
