import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { useIntl, Link } from 'gatsby-plugin-react-intl'
import Bounce from 'react-reveal/Bounce'

import logo from '../img/logo.svg'
import './navbar.css'

const Navbar = ({ viewerIsOpen }) => {
  const [isMobileMenuActive, setMobileMenuActive] = React.useState(false)
  const toggleMobileMenu = () => setMobileMenuActive(!isMobileMenuActive)
  const intl = useIntl()

  return (
    <StaticQuery
      query={graphql`
        query {
          allWpPage(sort: { fields: id }, limit: 5) {
            edges {
              node {
                title
                slug
              }
            }
          }
        }
      `}
      render={data => (
        <nav
          className={`navbar is-transparent cl-effect-1 ${
            viewerIsOpen ? `is-hidden` : ''
          }`}
          role="navigation"
          aria-label="main navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              <Bounce left>
                <Link to="/" className="navbar-item">
                  <figure className="image">
                    <img
                      id="logo"
                      src={logo}
                      alt="Lemiesz team"
                      style={{ minHeight: '75px' }}
                    />
                  </figure>
                </Link>
              </Bounce>
              {/* Hamburger */}
              <a
                role="button"
                className={`navbar-burger burger ${
                  isMobileMenuActive ? 'is-active' : ''
                }`}
                aria-label="menu"
                aria-expanded="false"
                data-target="mainNavbar"
                onClick={e => toggleMobileMenu()}
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>

            <div
              id="mainNavbar"
              className={`navbar-menu ${isMobileMenuActive ? 'is-active' : ''}`} //cl-effect-5
            >
              <div className="navbar-end">
                <Link
                  className="navbar-item cl-link" // cl-link
                  to="/blog"
                  key="blog"
                >
                  <span data-hover="Aktualności">{intl.messages.news}</span>
                </Link>
                <Link
                  className="navbar-item cl-link" // cl-link
                  to="/gallery"
                  key="Galeria"
                >
                  <span data-hover="Galeria">{intl.messages.gallery}</span>
                </Link>
                <Link
                  className="navbar-item cl-link" // cl-link
                  to="/kontakt"
                  key="Kontakt"
                >
                  <span data-hover="Kontakt">{intl.messages.contact}</span>
                </Link>
              </div>
            </div>
          </div>
        </nav>
      )}
    />
  )
}

export default Navbar
