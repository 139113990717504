import React from 'react'
import Helmet from 'react-helmet'
import favicon from '../img/favicon.ico'
import Navbar from './Navbar'
import './all.sass'
// import '../styles/stickyFooter.css'
import Footer from './Footer'

const TemplateWrapper = ({ children, viewerIsOpen }) => (
  <div>
    <Helmet title="Klub sportowy Lemiesz Team">
      <meta httpEquiv="content-language" content="pl" />
      <meta charSet="utf-8" />
      <link rel="icon" href={favicon} />
      <meta
        name="description"
        content="Strona przeznaczona dla wszystkich pasjonatów lekkiej atletyki. Jako klub sportowy pomagamy zrealizować cele treningowe, wymieniamy się doświadczeniami i prezentujemy informacje dotyczące Naszych zawodników."
      />
      <meta
        name="keywords"
        content="lekkoatletyka, athletics, masters, bieganie, skok w dal, trójskok, weterani, aktywny senior, sport, zawody, plan treningowy"
      />
    </Helmet>
    <Navbar viewerIsOpen={viewerIsOpen} />
    <div>{children}</div>
    <Footer />
  </div>
)

export default TemplateWrapper
